import './styles/App.css';
import { SpeedInsights } from "@vercel/speed-insights/react"
import Landing from "./pages/landing";

function App() {
    return (
        <div>
            <Landing/>
            <SpeedInsights/>
        </div>
    );
}

export default App;