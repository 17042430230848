import React, { useEffect, useRef } from 'react';
import Navbar from '../components/navbar';
import '../styles/landing.css';
import Mesh from '../assets/mesh';
import Lottie from "lottie-react";

function Landing() {
    const lottieRef = useRef(null);

    useEffect(() => {
        if (lottieRef.current) {
            lottieRef.current.setSpeed(2.5);
        }
    }, []);

    return (
        <section className="hero-section">
            <div className="navbar-container">
                <Navbar/>
            </div>
            <div className="hero-text">
                <h1>Meet your next <br/></h1>
                <div className="key-text-container">
                    <h1 id='key-text'>passionate</h1>
                    <div className="mesh-overlay">
                        <Lottie
                            lottieRef={lottieRef}
                            animationData={Mesh}
                            style={{ width: '534px', height: '212px' }}
                        />
                    </div>
                </div>
                <h1> developer.</h1>
            </div>
        </section>
    );
}

export default Landing;
