import '../styles/navbar.css';

const Navbar = () => {

    return (
        <div className="container">
            <div className="navbar">
                <div id="navbar-left">
                    <div className="logo">
                        <a id="logo-ref" href="/">
                            <p className="logo-text">Dimitri Liauw</p>
                        </a>
                    </div>
                </div>
                <div className="divider"></div>
                <div id="navbar-right">
                    <nav className="navbar-links">
                        <a href="/">Projects</a>
                        <a href="/resume">Resume</a>
                        <a href="mailto:dimitri.liauw@vosuki.com">Contact</a>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
